import {Inject, Injectable} from '@angular/core';
import {MediaLibraryEnvConfig, MediaLibraryEnvConfigInterface} from "./media-library-env.config";

@Injectable()
export class MediaLibraryEnvService {

  constructor(
    @Inject(MediaLibraryEnvConfig) public mediaLibraryEnvConfig: MediaLibraryEnvConfigInterface
  ) {
  }

  getBucket() {
    return this.mediaLibraryEnvConfig.bucket;
  }
}
