import {InjectionToken, Type} from "@angular/core";

export const MediaLibraryEnvConfig = new InjectionToken("MediaLibraryModalEnvConfig");
export const BUCKET_TOKEN = new InjectionToken("BucketToken");

export interface MediaLibraryEnvConfigInterface {
  bucket: string;
  staticResourceManagerBasePath?: string,
  multipartFileName?: string;
  functions?: {
    replace?: {
      replaceService: Type<any>;
      renameFunctionName: string;
    }
    rename?: {
      replaceService: Type<any>;
      renameFunctionName: string;
    }
  }

}
