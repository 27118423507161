import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {FileShowDto} from "@sv/swagger/media-manager-api";
import {MediaLibraryEnvService} from "@sv/sv-common/lib/env/media-library-env.service";

@Injectable({providedIn: "root"})
@Pipe({
  name: 'toFilePath',
  standalone: true
})
@Injectable()
export class ToFilePathPipe implements PipeTransform {

  constructor(
    private mediaLibraryModalEnv: MediaLibraryEnvService
  ) {
  }

  transform(value: unknown | FileShowDto | FileShowDto[] | any, useThumbnail: boolean = false): string | null {
    if (useThumbnail) {
      return `${this.mediaLibraryModalEnv.mediaLibraryEnvConfig.staticResourceManagerBasePath}/files/${value.fullPath}?useThumbnail=true`
    } else {
      return `${this.mediaLibraryModalEnv.mediaLibraryEnvConfig.staticResourceManagerBasePath}/files/${value.fullPath}`
    }
  }

}
